
function App() {
  return (
    <div className="App">
      <a href="https://astonjames.co.nz"><img src="/AstonJames_Tagline_Black.svg" /></a>
      <h1>This service is not currently publicly available</h1>
      <p>Please contact us if you wish to use this service</p>
      <a href="mailto:contact@astonjames.co.nz"><button>Contact us</button></a>
    </div>
  );
}

export default App;
